import React from 'react';

import { createRoot } from 'react-dom/client';

import FullscreenPreloader from '~/components/atoms/FullscreenPreloader';
import './polyfills';
import i18n from '~/i18n/config';
import '~/index.scss';

i18n.init(async () => {
  import('~/bootstrap'); // import before App

  const App = await import('./App');

  // eslint-disable-next-line import/no-named-as-default-member
  const root = createRoot(document.getElementById('root') as HTMLElement);

  root.render(
    <React.StrictMode>
      {/*Localization. Move Suspense here from Router component*/}
      <React.Suspense fallback={<FullscreenPreloader />}>
        <App.default />
      </React.Suspense>
    </React.StrictMode>,
  );
});
